import {filter, keyBy, mapValues, map, forEach, keys, find} from "lodash";
import Mustache from 'mustache';

export const parseField = (field) => {
    if (field.type && field.type.startsWith("Json")) {
        return parseJson(field.value)
    } else {
        return field.value;
    }
};

const parseJson = (value) => {
    try {
        return JSON.parse(value);
    } catch {
        return value
    }
}

export const parseFields = (fields) => {
    const parsed = map(fields, (field) => ({
        ...field,
        value: parseField(field)
    }));
    return parsed;
};

export const getFieldsForContent = (block, culture) => {
    const filtered = filter(block.content, (content) => content.culture === culture);
    if (filtered.length >= 1) {
        return parseFields(filtered[0].fields);
    }
    return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
    const content = getFieldsForContent(block, culture);
    return mapValues(keyBy(content, 'name'), 'value');
};

export const isBrowserSupported = () => {
    const {detect} = require('detect-browser');
    const browser = detect();
    const name = browser.name;

    if (name === 'ie') {
        return false;
    } else {
        return true;
    }


    let supported;
    switch (name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
        case 'safari':
        case 'ios':
        case 'crios':
            supported = true;
            break;
        default:
            supported = false;
    }
    return supported;
};

export const mergeText = (text, textToAdd, separator = '|') => {
    if (text.length > 0 && separator !== undefined) {
        if (text.includes(separator)) {
            return `${text} ${textToAdd}`
        } else {
            return `${text} ${separator} ${textToAdd}`
        }
    }

    return textToAdd;
};

export const hasWindow = () => {
    let hasWindow = false;
    try {
        if (window) {
            hasWindow = true;
        }
    } catch (ex) {
        hasWindow = false;
    }

    return hasWindow;
};

export const parseText = (text, data) => {
    if (data) {
        return Mustache.render(text, data)
    }
    return text;
}

export const defaultPageNames = {
    home: '/',
    vehicles: 'stock',
    vehicleDetail: 'vehicle',
    vehicleTakeOver: 'takeover',
    vehicleCompare: 'vehicle-compare',
    vehicleSearchAgent: 'search-agent',
    vehicleReservation: 'reservation',
    search: 'search',
    contact: 'contact',
    services: 'services',
    about: 'about',
    privacy: 'privacy',
    blog: 'blog',
    blogDetail: 'blog-detail',
    favorites: 'favorites',
    account: 'account',
    login: 'login',
    registration: 'registration',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
};

export const resolveUrlsMultiLanguage = (pages) => {
    // Used for BW compatible, but depricated
    let result = {}
    forEach(keys(defaultPageNames), (pageType) => {
        const pageInDb = find(pages, (p) => p.type === pageType)
        const defaultPageValue = defaultPageNames[pageType]
        let translations = (pageInDb) ? JSON.parse(pageInDb.translations) : {"en": defaultPageValue, "nl": defaultPageValue, "fr": defaultPageValue, "de": defaultPageValue}

        forEach(keys(translations), (lng) => {
            if (translations[lng] === "") {
                translations[lng] = defaultPageValue
            }
        })

        result[defaultPageValue] = translations
    })

    const customPages = filter(pages, (p) => p.type === 'custom')
    forEach(customPages, (page) => {
        const defaultPageValue = page.url
        let translations = JSON.parse(page.translations)

        forEach(keys(translations), (lng) => {
            if (translations[lng] === "") {
                translations[lng] = defaultPageValue
            }
        })
        result[defaultPageValue] = translations
    })

    return result;
}
