/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'dealer-website-components/dist/css/index.css'
import React from 'react';
import {DataContextProvider} from 'dealer-website-components';
import {config as themeConfig} from 'dealer-website-components'
import {graphql, StaticQuery} from "gatsby";
import RootWrapper from "./src/components/RootWrapper";

// const wrapRootElement = ({element}) =>  <DataContextProvider themeConfig={themeConfig}>{element}</DataContextProvider>
const wrapRootElement = ({element}) =>  <RootWrapper>{element}</RootWrapper>

// const wrapRootElement = ({element}) => {
//     return (
//         <StaticQuery
//             query={graphql`
//                query {
//                   site {
//                     siteMetadata {
//                       title
//                     }
//                   }
//                 }
//             `}
//             render={(data) => {
//                 debugger;
//                 console.log("SVLIIII");
//                 console.log(data);
//                 return <DataContextProvider themeConfig={themeConfig}>{element}</DataContextProvider>;
//             }}
//         />
//     );
// };


const scrollTo = (id) => () => {
    let offset = 0;
    const el = document.querySelector(id);

    try {
        const stickies = document.getElementsByClassName("sticky");
        for (let key in stickies) {
            const element = stickies[key];
            if (element && element.offsetHeight && element.offsetHeight > 0) {
                offset += element.offsetHeight
            }
        }
    } catch (ex) {
    }

    if (el) return window.scrollTo(0, el.offsetTop - offset);
    return false
};


const onRouteUpdate = ({location: {hash}}) => {
    if (hash) {
        window.setTimeout(scrollTo(hash), 200)
    }
};

export {wrapRootElement, onRouteUpdate};




